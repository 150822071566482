import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import elkdiagram from "../../Assets/Projects/elkdiagram.png";
import cloudbenchmark from "../../Assets/Projects/cloudbenchmark.png";
import ABAC from "../../Assets/Projects/ABAC.png";
import cuuamvng from "../../Assets/Projects/cuuamvng.jpg";
import kofallstar from "../../Assets/Projects/kofallstar.jpg";
import baomoi from "../../Assets/Projects/baomoi.png";
import OpenNebula from "../../Assets/Projects/OpenNebula.jpg";
import powerdns from "../../Assets/Projects/powerdns.jpg";
import nginx from "../../Assets/Projects/nginx.png";
import LaTeX_project from "../../Assets/Projects/LaTeX_project.jpg";
import ceph from "../../Assets/Projects/ceph-project.jpg";
import hdfs from "../../Assets/Projects/hadoop.jpg";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hdfs}
              isBlog={false}
              // blogLink="https://sonnh.net/technicals/install-cephadm-and-benchmark/"
              // haveGithub={true}
              title="HDFS Security System"
              description="In this project, I set up a hadoop distributed file system by using kerberos to authenticate, ldap as user identify, ranger as policy management. All of them support manage user who access, read/write and submit a yarn task to hdfs. User create user by my API write in python."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://www.youtube.com/watch?v=a--_aMIr9eI&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z&index=9"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ceph}
              isBlog={true}
              blogLink="https://sonnh.net/technicals/install-cephadm-and-benchmark/"
              // haveGithub={true}
              title="CEPH install and benchmark"
              description="In this project, I install a ceph cluster with 3 servers master (mgr, mon) and 3 server storage (3 osd) with some module monitor. At the end, I do some chaos test such as down node, detach disk to verify system."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://www.youtube.com/watch?v=a--_aMIr9eI&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z&index=9"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nginx}
              isBlog={true}
              blogLink="https://sonnh.net/technicals/nginx-bench-mark-between-arm-and-x86/"
              // haveGithub={true}
              title="Compare nginx performance between ARM and x86"
              description="In this project, I use ApacheBench and wrk2 to load test nginx was deploy on ARM architecture. Compare when deploy in x86, I conclude which way is better. Result are report and monitor metric on grafana."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://www.youtube.com/watch?v=a--_aMIr9eI&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z&index=9"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={powerdns}
              isBlog={false}
              // haveGithub={true}
              title="Network for disaster recovery benchmark"
              description="In this project, I use ping, iperf, netperf and speedtest to diagnostic network performance is satisfied of not to setup DR site. Result are report, monitor metric on grafana and some diagram picture draw by pandas"
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://www.youtube.com/watch?v=a--_aMIr9eI&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z&index=9"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={powerdns}
              isBlog={true}
              blogLink="https://sonnh.net/technicals/how-to-install-powerdns/"
              title="PowerDNS Benchmark"
              description="In this project, I setup a high availability DNS resolve system, include Recursor and Authoritative. Use PowerDNS and PowerDNS-Admin as admin tool, I setup cluster with 5 server to resolve internal DNS. Then, use py script and dnsflood to load test system. Result are report, monitor metric on grafana and some diagram picture draw by pandas"
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://www.youtube.com/watch?v=a--_aMIr9eI&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z&index=9"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cloudbenchmark}
              isBlog={false}
              haveGithub={true}
              title="Cloud provider benchmark"
              description="We look at the machines provided by some of CSP that have exact specifications or
              not. First, we research some common tool use for benchmark system metric. Such as passmark,
              IOZone, FiO. Second, we build infrastructure (GCP, AWS) with terraform, include auto install software
              script. Finally, we build a website, which display all of metric above by chart."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://www.youtube.com/watch?v=a--_aMIr9eI&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z&index=9"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={LaTeX_project}
              isBlog={true}
              title="HCMUIT Thesis template"
              description="This is template for HCMUIT thesis written by Latex. "
              blogLink="https://sonnh.net/technicals/hcmuit-thesis-latex-template/"
              ghLink="https://github.com/sonnh-uit/HCMUIT_thesistemplate"
              demoLink="https://github.com/sonnh-uit/HCMUIT_thesistemplate?tab=readme-ov-file#demo"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={elkdiagram}
              isBlog={true}
              title="ELK Stack Implementation"
              description="In this project, we build a cluster with seven centos server, install and configure
              services (nginx, php, mysql, keepalive, ids) for filebeat cluster. Then install and setting configuration ELK
              stack for collect and analysis log, create monitoring charts, generating alerts based on server metrics to
              notify system engineers by slack. All of deploy and configuration excute by Ansible."
              // blogLink="https://blogs.sonnh.net"
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ABAC}
              isBlog={false}
              title="Encrypt And Access Control On Sql Database Management System"
              description="This is my Undergraduate Thesis.In this project, we are research and evaluate some access control models. Report and implement a
              feature which use attribute based encryption to control access in mysql database.."
              // blogLink="https://blogs.sonnh.net"
              ghLink="https://github.com/sonnh-uit/TheSis"
              demoLink="https://www.youtube.com/watch?v=1ORcUpeATHo&list=PLGuzxickUWx3FP6-5kWttHF1vcDuP859Z"      
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Product </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few product I am (or was) operating.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={OpenNebula}
              isBlog={false}
              haveGithub={true}
              title="OpenNebula Private Cloud"
              description="In this product, I hand on all scope of work related: setup new server node (or remove node); produce VM on demand; setup custom API to match require; create and manage VM, template;write script as needed; migrate VM from virtualBox to OpenNebula."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://baomoi.com/"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={baomoi}
              isBlog={false}
              haveGithub={true}
              title="Báo mới"
              description="In this product, I maintaince exist system with: setup new gateway for cms, beta and product online newspaper site; support developer release their feature; migrate services from old DC to new DC (follow VNG big plan)."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://baomoi.com/"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cuuamvng}
              isBlog={false}
              haveGithub={true}
              title="Cửu Âm VNG"
              description="In this product, I maintaince exist system with: setup collect game log and push to DP, cost management and optimize, support setup API for open/close new server."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://cuuam.360game.vn/"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kofallstar}
              isBlog={false}
              haveGithub={true}
              title="KOF AllStar VNG - Quyền Vương Chiến"
              description="In this product, I maintaince exist system with: cost management and optimize, process to close product."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://www.facebook.com/kofallstar.vng.vn/"      
            />
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
