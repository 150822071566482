import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiPython,
  DiLinux ,
  DiGoogleCloudPlatform ,
} from "react-icons/di";
import {
  SiRedis,
  SiPostgresql,
  SiMysql,
  SiAnsible ,
  SiTerraform,
  SiAmazonec2,
  SiAmazonaws,
  SiGrafana ,
  SiPrometheus ,
  SiNginx,
  SiApachekafka,
  SiLogstash ,
  SiElasticsearch,
  SiKibana,
  SiApachehadoop ,
  SiApacheairflow ,
  SiMlflow,
  SiDocker  ,
  SiKubernetes ,
  SiOpennebula ,
} from "react-icons/si";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiOpennebula  />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql  />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis  />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiLinux   />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAnsible    />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTerraform     />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonec2      />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws       />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGoogleCloudPlatform        />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGrafana         />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNginx         />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPrometheus          />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiApachekafka           />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiElasticsearch            />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLogstash             />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKibana              />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiApachehadoop               />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiApacheairflow                />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMlflow                 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker                   />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes                    />
      </Col>
      
    </Row>
  );
}

export default Techstack;
